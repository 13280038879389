.bg_img{
  /* background-image: url("/src/assets/pricingBackImg.png"); ok  ttt */
  background-image: url("/src/components/assets/updated.jpg");
}
.bg_law{
  background-image: url("/src/components/assets/business-lawyer.jpg")
}
.bg_law2{
  background-image: url("/src/components/assets/business-lawyerBlog.jpg");

  
}
.bg_gaval{
  background-image: url("/src/components/assets/gaval.jpg")
}
.bg_map{
  background-image: url("/src/components/assets/map.jpg")
}
.bg_male{
  background-image: url("/src/components/assets/malelawyer.jpg")
}
@import url('https://fonts.googleapis.com/css?family=Roboto:700');

p {
  text-shadow: 0 0 7px rgba(255,255,255,.3), 0 0 3px rgba(255,255,255,.3);
}
.container {

  font-size: 1.5rem;
  
  height:  auto;
  display: flex;
  align-items: center;
}

.animation {
  height:50px;
  overflow:hidden;
  margin-left: 1rem;
}

.animation > div > div {
  /* padding: 0.25rem 0.75rem; */
  height:2.81rem;
  margin-bottom: 2.81rem;
  display:inline-block;
}

.animation div:first-child {
  animation: text-animation 8s infinite;
}

.first div {
  color:  #d5b661;
  
}
.second div {
  color:  #d5b661;
}
.third div {
  color:  #d5b661;
}
.fourth div{
  color:  #d5b661;
}

@keyframes text-animation {
  0% {margin-top: 0;}
  10% {margin-top: 0;}
  20% {margin-top: -5.62rem;}
  30% {margin-top: -5.62rem;}
  40% {margin-top: -11.24rem;}
  60% {margin-top: -11.24rem;}
  70% {margin-top: -5.62rem;}
  80% {margin-top: -5.62rem;}
  90% {margin-top: 0;}
  100% {margin-top: 0;}
}

